<template>
    <div id="pageList" class="SaleBillList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">销售单号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.code = validForbid(e)" placeholder="请输入销售单号进行模糊匹配查询..." v-model="pageList.queryParam.code" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">所在城市：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('cityId',el)" v-model="pageList.queryParam.cityId" placeholder="请选择所在城市" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'cityId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">销售类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('saleType',el)" v-model="pageList.queryParam.saleType" placeholder="请选择销售类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'saleType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">货物类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('goodsTypeId',el)" v-model="pageList.queryParam.goodsTypeId" placeholder="请选择货物类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'goodsTypeId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">客户：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.customerId = validForbid(e)" placeholder="请输入客户进行模糊匹配查询..." v-model="pageList.queryParam.customerId" clearable/>

                    </el-col>
                    <el-col :span="3" class="myColTitle">项目名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入项目名称进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">客户报价单：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.customerQuotationId = validForbid(e)" placeholder="请输入项目客户报价单名称进行模糊匹配查询..." v-model="pageList.queryParam.customerQuotationId" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">结算周期：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('jsType',el)" v-model="pageList.queryParam.jsType" placeholder="请选择结算周期" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'jsType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">发票类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('invoiceType',el)" v-model="pageList.queryParam.invoiceType" placeholder="请选择发票类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'invoiceType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">客户经理：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('managerPersonId',el)" v-model="pageList.queryParam.managerPersonId" placeholder="请选择客户经理" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'managerPersonId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">调度专员：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('schedulePersonId',el)" v-model="pageList.queryParam.schedulePersonId" placeholder="请选择调度专员" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'schedulePersonId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">是否停用：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('flag',el)" v-model="pageList.queryParam.flag" placeholder="请选择是否停用" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'flag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">审核状态：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('status',el)" v-model="pageList.queryParam.status" placeholder="请选择审核状态" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'status'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_CODE" label="销售单号"/>
                <el-table-column prop="F_CITY_ID" label="所在城市"/>
                <el-table-column prop="F_SALE_TYPE" label="销售类型"/>
                <el-table-column prop="F_CUSTOMER_ID" label="客户"/>
                <el-table-column prop="F_NAME" label="项目名称"/>
                <el-table-column prop="F_CUSTOMER_QUOTATION_NAME" label="客户报价单"/>
                <el-table-column prop="F_JS_TYPE" label="结算周期"/>
                <el-table-column prop="F_INVOICE_TYPE" label="发票类型"/>
                <el-table-column prop="F_POINT" label="票点"/>
                <el-table-column prop="F_MANAGER_PERSON_ID" label="客户经理"/>
                <el-table-column prop="F_SCHEDULE_PERSON_ID" label="调度专员"/>
                <el-table-column prop="F_FLAG" label="是否停用"/>
                <el-table-column prop="F_STATUS" label="审核状态"/>
                <el-table-column prop="F_TIME" label="创建时间"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import SaleBillCard from './SaleBillCard';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "SaleBillList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                cityData:[],
                goodsData:[],
                managerData:[],
                scheduleData:[],
                pageList: {
                    queryParam: {
                        moduleType:'SaleBillList',
                       code:"", 
                       cityId:"", 
                       saleType:"",
                       name:"", 
                       goodsTypeId:"", 
                       customerQuotationId:"", 
                       jsType:"", 
                       invoiceType:"", 
                       managerPersonId:"", 
                       schedulePersonId:"", 
                       flag:"", 
                       status:"", 
                       time:"" 
                    },
                    modelComp: SaleBillCard,
                    modelMethod: "/saleBill/pageData"
               }
            })
            onMounted(async ()=>{
                let data=await utils.$$api.getSaleBillPageCompData({});
                dataObj.cityData = utils.$$lghdUtils.dealSelectData(data.cityData);
                dataObj.goodsData = utils.$$lghdUtils.dealSelectData(data.goodsData);

                dataObj.managerData = utils.$$lghdUtils.dealSelectData(data.personData);
                dataObj.scheduleData = utils.$$lghdUtils.dealSelectData(data.personData);

            })
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'cityId'==params.comboId){
                        return dataObj.cityData;
                    }
                    if(params && 'saleType'==params.comboId){
                        return [{value:0,label:'项目'},{value:1,label:'临时'}]
                    }
                    if(params && 'goodsTypeId'==params.comboId){
                        return dataObj.goodsData;
                    }

                    if(params && 'jsType'==params.comboId){
                        return [{value:0,label:'日结'},{value:1,label:'月结'}]
                    }
                    if(params && 'invoiceType'==params.comboId){
                        return [{value:0,label:'专票'},{value:1,label:'普票'}]
                    }
                    if(params && 'managerPersonId'==params.comboId){
                        return dataObj.managerData;
                    }
                    if(params && 'schedulePersonId'==params.comboId){
                        return dataObj.scheduleData;
                    }
                    if(params && 'status'==params.comboId){
                        return [{value:0,label:'待审核'},{value:1,label:'已拒绝'},{value:2,label:'待签约'},{value:3,label:'已签约（待生效）'},
                            {value:4,label:'已签约（已生效）'},{value:5,label:'已签约（已失效）'}]
                    }
                    if(params && 'flag'==params.comboId){
                        return [{value:0,label:'启用'},{value:1,label:'禁用'}]
                    }
                }
            })

            return{
                ...toRefs(dataObj),comboSelect
            }
        }
    });
</script>

<style scoped>
    .SaleBillList{
        width: 100%;
    }
</style>